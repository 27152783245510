import React from 'react';
import { Button } from '@chakra-ui/react';

export const BlueButton = ({ children, onClick, ...rest }) => {
  return (
    <Button
      {...rest}
      rounded={'xl'}
      size={'lg'}
      fontWeight={'normal'}
      bg="dfcthree.500"
      color="dfcwhite.50"
      border="2px"
      variant="outline"
      transform="transform 0.3s ease"
      cursor="pointer"
      _hover={{
        bg: 'dfcthree.200',
        color: 'dfcwhite.900',
        boxShadow: 'dark-lg',
        _disabled: {
          bg: 'dfcthree.50',
          color: 'dfcwhite.900',
          boxShadow: 'none'
        },
      }}
      px={6}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
