// import * as React from 'react'
// import { Box, Button, SimpleGrid, useColorModeValue, Container } from '@chakra-ui/react'
// import { CardWithAvatar } from './StripeCard/CardWithAvatar'
// import { FollowerCount } from './StripeCard/FollowerCount'
// import { UserInfo } from './StripeCardUserInfo'
// import data from './StripeCard/data.json'

// export const StripeCard = () => (
//   <Container>
//     <Heading >Please donate to our cause!</Heading>
//   {/* <Box bg={useColorModeValue('gray.100', 'gray.800')} px={{ base: '6', md: '8' }} py="12">
//     <Box as="section" maxW={{ base: 'xs', md: '3xl' }} mx="auto">
//       <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6">
//         {data.map((user) => {
//           const { name, bio, src, isVerified, followerCount } = user
//           return (
//             <CardWithAvatar key={name} avatarProps={{ src, name }}>
//               <UserInfo mt="3" name={name} bio={bio} isVerified={isVerified} />
//               <FollowerCount my="4" count={followerCount} />
//               <Button variant="tertiary" colorScheme="purple" rounded="full" size="sm" width="full">
//                 View Profile
//               </Button>
//             </CardWithAvatar>
//           )
//         })}
//       </SimpleGrid>
//     </Box>
//   </Box> */}
//   </Container>
// )
import React from 'react';
import { Box, Button, Text, Heading, useColorModeValue as mode, SimpleGrid, Link, Image, Center} from '@chakra-ui/react';
import { CardWithAvatar } from '../components/striprCards/CardWithAvatar';
import { UserInfo } from '../components/striprCards/UserInfo';
import data from '../components/striprCards/oneTimeData.json'

export default function Donation() {
  return (
    <Box
      bg={mode('white', 'gray.700')}
      p={8}
      borderRadius="lg"
      shadow="base"
      textAlign="center"
    >
      <Heading as="h1" mb={4} fontSize="2xl" color={mode('purple.600', 'purple.300')}>
        Donate to Support Us
      </Heading>
      <Text mb={6} color={mode('gray.600', 'gray.400')}>
        Your generous donation helps us continue our mission. Click below to contribute through our secure Stripe payment gateway.
      </Text>
       <Box bg={mode('gray.100', 'gray.800')} px={{ base: '6', md: '8' }} py="12">
        <Box as="section" maxW={{ base: 'xs', md: '3xl' }} mx="auto">
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6">
            {data.map((user) => {
              const { name, bio, src, link } = user
              return (
                <CardWithAvatar key={name} avatarProps={{ src, name }}>
                  <UserInfo mt="3" name={name} bio={bio} />
                  <Link href={link} Target="_blank" rel="noopener nore" isExternal>
                    <Button variant="tertiary" colorScheme="green" rounded="full" size="lg" width="full">
                      DONATE
                    </Button>
                  </Link>
                </CardWithAvatar>
              )
            })}
          </SimpleGrid>
        </Box>
        <Text mt={6}>-------- or if you prefer Venmo  -------- </Text>
        <Center>
          <Link 
            mt={4}
            href="https://venmo.com/" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }} 
            isExternal
          >
            <Image 
              src="https://downriver-foster-closet.s3.us-east-2.amazonaws.com/venmo+code.png" 
              boxSize='200px'
              objectFit='cover' 
              alt="Venmo for Downriver Foster Closet" 
            />
          </Link>
        </Center>
      </Box>
    </Box>
  );
}