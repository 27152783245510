import {
  Box,
  Heading,
  Stack,
  Text,
  Flex,
  AspectRatio,
  useColorModeValue as mode,
  Button,
  Center,
  LightMode,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Thermometer from 'react-thermometer-chart';
import { getHomePageContent } from '../api';

export default function Home() {
  const [dfcData, setDFCHomeData] = useState(0);
  const [loaded, setLoaded] = useState(0);

  const getInitData = async () => {
    return await getHomePageContent('DFCHome1').then(res => {
      setDFCHomeData(res.data.data.getLandingPage.data);
      setLoaded(1);
      console.log('ran');
    });
  };

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      <Box
        ml="0"
        mr="0"
        mt="0"
        as="section"
        bg="gray.800"
        py="12"
        position="relative"
        h={{ base: '1100px', md: '1200px' }}
        bgImage="https://downriver-foster-closet.s3.us-east-2.amazonaws.com/home-page/kids.jpg"
        bgSize="cover"
        bgPosition="center"
        _after={{
          content: `""`,
          display: 'block',
          w: 'full',
          h: 'full',
          bg: 'blackAlpha.700',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        }}
      >
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          h="full"
          zIndex={1}
          position="relative"
        >
          <Center
            flexDirection="column"
            textAlign="center"
            color="white"
            h="full"
          >
            <Heading size="2xl" fontWeight="extrabold">
              Downriver Foster Closet
            </Heading>
            <Text fontSize="2xl" fontWeight="bold" mt="3">
              We provide essential items for children in foster care
            </Text>
            <Text fontSize="lg" fontWeight="medium" mt="3">
              We are dedicated to helping the lives of children in foster care.
              Your contributions make that happen rather than its items donated,
              volunteer hours put in, monetary donations without your support we
              could not make the difference we are in these kids’ lives.
            </Text>
            <LightMode>
              <Link
                href="https://www.facebook.com/Downriverfostercloset"
                target="_blank"
                rel="noopener nore"
                isExternal
                // Styles: {textDecoration= none}
              >
                <Button
                  colorScheme="blue"
                  size="lg"
                  mt="6"
                  fontWeight="bold"
                  fontSize="md"
                >
                  Follow Us on Facebook
                </Button>
              </Link>
              <Link
                href="/donation"
                rel="noopener nore"
              >
                <Button
                  colorScheme="purple"
                  size="lg"
                  mt="6"
                  fontWeight="bold"
                  fontSize="md"
                >
                  Donate to our cause!!
                </Button>
              </Link>
            </LightMode>
          </Center>
        </Box>
      </Box>
      <Box as="section" bg={mode('gray.200', 'gray.800')} pt="16" pb="24">
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          pb={'20'}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '3rem', lg: '2rem' }}
            mt="8"
            align={{ lg: 'center' }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: '520px' }}>
              <Heading
                as="h1"
                size="3xl"
                color={mode('dfcone.500', 'dfcwhite.50')}
                mt="8"
                fontWeight="extrabold"
                letterSpacing="tight"
              >
                {loaded ? dfcData.missionTitle : ''}
              </Heading>
              <Text
                color={mode('gray.600', 'gray.400')}
                mt="4"
                fontSize="lg"
                fontWeight="medium"
              >
                {loaded ? dfcData.missionText : ''}
              </Text>
            </Box>
            <Box
              pos="relative"
              w={{ base: 'full', lg: '560px' }}
              h={{ base: 'auto', lg: '560px' }}
              align="center"

            >
              <Flex
                flex={1}
                justify={'center'}
                align={'center'}
                position={'relative'}
                w={'full'}
                // display={{ base: 'none', md: 'flex' }}
              >
                <AspectRatio width={'full'} maxW="400px" ratio={2 / 3}>
                  <Thermometer
                    width="200px"
                    height="440px"
                    color="#815EA3"
                    background="#fff"
                    steps={3}
                    valueColor=""
                    minValue={0}
                    maxValue={loaded ? dfcData.tempCounterMax : 1500}
                    currentValue={loaded ? dfcData.tempCounterCurrent : 300}
                  >
                  </Thermometer>
                </AspectRatio>
              </Flex>
                <Text
                  mt='5'
                  size='lg'
                  color={mode('dfcone.500', 'dfcwhite.50')}
                >
                  Current Fundraiser Progress
                </Text>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
